<template>
  <v-card flat>
    <v-card-title>
      <v-text-field
        style="max-width: 400px;"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        clearable
        dense
        hide-details
      ></v-text-field>

      <v-spacer></v-spacer>
      <v-btn
        :to="{ path: '/gestor/clientes/adicionar' }"
        align="center"
        color="secondary"
        class="white--text"
      >
        adicionar
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-data-table
        v-if="clientes"
        :headers="headers"
        :search="search"
        :items="clientes"
        :items-per-page="10"
        :sort-desc="true"
        class="data-tables data-tables__row-click"
        @click:row="goToCliente"
      >
        <template v-slot:item.status="{ item }">
          {{ item.status | status }}
        </template>
        <template v-slot:item.data_cadastro="{ item }">
          {{ item.data_cadastro | dateFormat("dd/MM/yyyy") }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { fetchClientes } from "@/api/gestor/clientes.js";

export default {
  name: "ListaClientes",

  data() {
    return {
      loading: true,
      clientes: [],
      search: "",
      display: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Razão social",
          value: "razao_social",
        },
        {
          text: "Nome fantasia",
          value: "nome_fantasia",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Cadastro",
          value: "data_cadastro",
        },
      ];
    },
  },

  methods: {
    goToCliente(item) {
      this.$router.push({ path: `/gestor/clientes/${item.id}` });
    },
    getClientes() {
      this.loading = true;
      fetchClientes()
        .then((response) => {
          this.clientes = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getClientes();
  },
};
</script>

<style scoped lang="scss"></style>
